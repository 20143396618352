<template>
  <div id="workflow-settings">
    <!-- action bar -->

    <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 125px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="workflow">
          <!-- general settings -->

          <GeneralSettings
            ref="GENERAL_SETTINGS"
            :mode="mode"
            :name.sync="name"
            :description.sync="description"
            :initiate-using.sync="initiateUsing"
            :ocr-credit="ocrCredit"
            :prefix.sync="prefix"
            :link-master-form-id.sync="linkMasterFormId"
            :smtp-email-id.sync="smtpEmailId"
            :user-list="userList"
            :coordinator.sync="coordinator"
            :superuser.sync="superuser"
            :file-size.sync="fileSize"
            :is-active="tab === 'GENERAL_SETTINGS'"
            @click="tab = 'GENERAL_SETTINGS'"
            @credit="updateCredit"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- connection settings -->

          <ConnectionSettings
            ref="CONNECTION_SETTINGS"
            :value="connections"
            :form-id="initiateUsing.formId"
            :is-active="tab === 'CONNECTION_SETTINGS'"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- business settings -->

          <BusinessSettings
            ref="BUSINESS_SETTINGS"
            :value="sla"
            :is-active="tab === 'BUSINESS_SETTINGS'"
          />

          <!-- ... -->

          <BaseSeparator has-left-inset />

          <!-- SLA settings -->

          <SLASettings
            ref="SLA_SETTINGS"
            :value="sla"
            :user-list="userList"
            :form-id="initiateUsing.formId"
            :is-active="tab === 'SLA_SETTINGS'"
          />

          <!-- ... -->

          <!-- <WorkInProgress
            v-if="tab === 'OTHER_SETTINGS'"
            feature="Other Settings"
          /> -->
        </ValidationObserver>

        <!-- ... -->

        <!-- KanbanViewSettings -->
        <KanbanViewSettings
          ref="KANBAN_VIEW_SETTINGS"
          :mode="mode"
          :workflow="workflow"
          :kanban-cards.sync="kanbanCards"
          :is-active="tab === 'KANBAN_VIEW_SETTINGS'"
        />
        <!-- ... -->

        <!-- ReportSettings -->
        <ReportSettings
          ref="REPORT_SCHEDULER"
          :value="reportSettings"
          :mode="mode"
          :user-list="userList"
          :is-active="tab === 'REPORT_SCHEDULER'"
        />
        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import GeneralSettings from "./components/general-settings/GeneralSettings.vue";
import ConnectionSettings from "./components/connection-settings/ConnectionSettings.vue";
import SLASettings from "./components/sla-settings/SLASettings.vue";
import BusinessSettings from "./components/business-settings/BusinessSettings.vue";
import { cloneDeep } from "lodash-es";
import { user } from "@/api/factory.js";
import KanbanViewSettings from "./components/kanban-view-settings/KanbanViewSettings.vue";
import ReportSettings from "./components/report-settings/ReportSettings.vue";

export default {
  name: "WorkflowSettings",

  components: {
    ActionBar,
    ValidationObserver,
    GeneralSettings,
    ConnectionSettings,
    SLASettings,
    BusinessSettings,
    KanbanViewSettings,
    ReportSettings,
  },

  props: {
    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GENERAL_SETTINGS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "connection",
          value: "CONNECTION_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "business",
          value: "BUSINESS_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "SLA",
          value: "SLA_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "kanban",
          value: "KANBAN_VIEW_SETTINGS",
        },
        {
          id: this.$nano.id(),
          label: "report Scheduler",
          value: "REPORT_SCHEDULER",
        },
      ],

      userList: [],

      // general settings

      name: "",
      description: "",
      initiateUsing: {},
      prefix: [
        {
          id: this.$nano.id(),
          key: "seperator",
          value: "-",
        },
        {
          id: this.$nano.id(),
          key: "reset",
          value: "",
        },
        {
          id: this.$nano.id(),
          key: "prefix",
          value: "REQ",
        },
        {
          id: this.$nano.id(),
          key: "autoIncrement",
          value: 1,
        },
      ],
      linkMasterFormId: 0,
      smtpEmailId: 0,
      coordinator: [],
      superuser: [],
      fileSize: 20,
      connections: [
        {
          name: "",
          actions: "",
          jiraLinkField: "",
          jiraCommentField: "",
        },
      ],
      sla: {
        settings: {
          workDays: [],
          workHours: {
            from: "",
            to: "",
          },
          timeZone: "",
          slaType: "STANDARD",
          slaMasterFormId: 0,
          workflowFormSLAFieldId: "",
          emailNotify: true,
          smsNotify: false,
        },
        rules: [],
      },

      kanbanCards: [],
      reportSettings: {
        durationType: "Week",
        duration: "",
        time: "",
        notifyTo: [],
        runningRequest: false,
        completedRequest: false,
      },
      credit: 0,
      isCreditRequired: false,
      ocrCredit: {},
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    workflow: {
      immediate: true,
      deep: true,
      handler() {
        const workflowSettings = cloneDeep(this.workflow.settings);
        this.name = workflowSettings.general.name;
        this.description = workflowSettings.general.description;
        this.initiateUsing = workflowSettings.general.initiateUsing;
        this.ocrCredit = workflowSettings.general.ocr || {};
        if (workflowSettings.general.ocr) {
          this.credit = workflowSettings.general.ocr.credit || 0;
          this.isCreditRequired =
            workflowSettings.general.ocr.required || false;
        }

        let proceed = false;
        if (workflowSettings.general.processNumberPrefix) {
          if (workflowSettings.general.processNumberPrefix.indexOf("[") > -1) {
            proceed = true;
          }
        }

        if (proceed) {
          let options = JSON.parse(
            workflowSettings.general.processNumberPrefix
          );
          let prefixs = [];
          options.forEach((item) => {
            if (item.key === "formColumn") {
              prefixs.push({
                id: this.$nano.id(),
                key: item.key,
                value: item.value.split(","),
              });
            } else if (item.key === "autoIncrement") {
              prefixs.push({
                id: this.$nano.id(),
                key: item.key,
                value: Number(item.value),
              });
            } else {
              prefixs.push(item);
            }
          });

          // this.prefix = options.find((row) => row.key === "prefix").value;
          this.prefix = prefixs;
        } else {
          if (workflowSettings.general.processNumberPrefix) {
            this.prefix = [
              {
                id: this.$nano.id(),
                key: "seperator",
                value: "-",
              },
              {
                id: this.$nano.id(),
                key: "reset",
                value: "year",
              },
              {
                id: this.$nano.id(),
                key: "prefix",
                value: workflowSettings.general.processNumberPrefix,
              },
              {
                id: this.$nano.id(),
                key: "autoIncrement",
                value: 1,
              },
            ];
          } else {
            this.prefix = [
              {
                id: this.$nano.id(),
                key: "seperator",
                value: "-",
              },
              {
                id: this.$nano.id(),
                key: "reset",
                value: "year",
              },
              {
                id: this.$nano.id(),
                key: "prefix",
                value: "REQ",
              },
              {
                id: this.$nano.id(),
                key: "autoIncrement",
                value: 1,
              },
            ];
          }
        }
        this.linkMasterFormId = workflowSettings.general.linkMasterFormId || 0;
        this.smtpEmailId = workflowSettings.general.smtpEmailId || 0;
        this.coordinator = workflowSettings.general.coordinator || [];
        this.superuser = workflowSettings.general.superuser || [];
        this.fileSize = workflowSettings.general.fileSize || 20;

        if (workflowSettings.general.slaSettings.slaType) {
          this.sla.settings = workflowSettings.general.slaSettings || {
            workDays: [],
            workHours: {
              from: "",
              to: "",
            },
            timeZone: "",
            slaType: "STANDARD",
            slaMasterFormId: 0,
            workflowFormSLAFieldId: "",
            emailNotify: true,
            smsNotify: false,
          };
          if (this.sla.settings.slaType === "ADVANCED") {
            this.sla.rules = [];
          } else {
            this.sla.settings.slaType = "STANDARD";
            this.sla.settings.slaMasterFormId = 0;
            this.sla.settings.workflowFormSLAFieldId = "";
            this.sla.rules = workflowSettings.general.slaRules || [];
          }
          if (!this.sla.settings.timeZone) {
            this.sla.timeZone = "";
          }
        } else {
          this.sla.settings = {
            workDays: [],
            workHours: {
              from: "",
              to: "",
            },
            timeZone: "",
            slaType: "STANDARD",
            slaMasterFormId: 0,
            workflowFormSLAFieldId: "",
            emailNotify: true,
            smsNotify: false,
          };
          this.sla.rules = [];
        }
        // console.log(workflowSettings.general.kanbanSettings);
        if (workflowSettings.general.kanbanSettings) {
          this.kanbanCards = workflowSettings.general.kanbanSettings;
        } else {
          this.kanbanCards = [];
        }

        if (workflowSettings.general.scheduleReport) {
          this.reportSettings = workflowSettings.general.scheduleReport;
        } else {
          this.reportSettings = {};
        }
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    updateCredit(value, required) {
      this.credit = value;
      this.isCreditRequired = required;
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: user.id,
          });
        });
      }
    },

    async saveSettings() {
      const isValid = await this.$refs.workflow.validate();

      if (!isValid) {
        return;
      }

      const workflowSettings = cloneDeep(this.workflow.settings);

      workflowSettings.general.name = this.name;
      workflowSettings.general.description = this.description;
      workflowSettings.general.initiateUsing = this.initiateUsing;
      workflowSettings.general.ocr = {
        credit: this.credit,
        required: this.isCreditRequired,
      };
      //workflowSettings.general.processNumberPrefix = this.prefix || "REQ-";
      // let requestNoGenerate = [
      //   { key: "seperator", value: "-" },
      //   { key: "reset", value: "" },
      //   { key: "prefix", value: this.prefix || "REQ" },
      //   { key: "year", value: "" },
      //   { key: "month", value: "" },
      //   { key: "formColumn", value: "" },
      //   { key: "autoIncrement", value: "1" },
      // ];
      let prefix = [];

      if (this.prefix.length) {
        this.prefix.forEach((item) => {
          if (item.key === "formColumn") {
            prefix.push({
              id: this.$nano.id(),
              key: item.key,
              value: item.value.join(","),
            });
          } else {
            prefix.push({
              id: this.$nano.id(),
              key: item.key,
              value: item.value,
            });
          }
        });

        //console.log(prefix);
      }
      workflowSettings.general.processNumberPrefix = JSON.stringify(prefix);
      workflowSettings.general.linkMasterFormId = this.linkMasterFormId;
      workflowSettings.general.smtpEmailId = this.smtpEmailId;
      workflowSettings.general.coordinator = this.coordinator;
      workflowSettings.general.superuser = this.superuser;
      workflowSettings.general.fileSize = this.fileSize;
      workflowSettings.general.slaSettings = this.sla.settings;
      if (this.sla.settings.slaType === "STANDARD") {
        this.sla.settings.slaMasterFormId = 0;
        this.sla.settings.workflowFormSLAFieldId = "";
        let level = 1;
        if (this.sla.rules.length) {
          this.sla.rules = this.sla.rules.filter(
            (rule) =>
              rule.name != "" &&
              rule.duration != "" &&
              (rule.users.length || rule.initiator)
          );
        }
        if (this.sla.rules.length) {
          this.sla.rules.forEach((rule) => {
            rule.level = level;
            level += 1;
          });
        }
      } else if (this.sla.settings.slaType === "ADVANCED") {
        this.sla.rules = [];
        if (
          this.sla.settings.slaMasterFormId &&
          this.sla.settings.workflowFormSLAFieldId
        ) {
          this.sla.rules.push({
            name: "SLA Master Policy",
            level: 1,
            masterFormId: this.sla.settings.slaMasterFormId,
            fieldId: this.sla.settings.workflowFormSLAFieldId,
          });
        }
      }
      workflowSettings.general.slaRules = this.sla.rules;

      workflowSettings.general.kanbanSettings = this.kanbanCards.length
        ? this.kanbanCards
        : [];

      if (this.reportSettings) {
        workflowSettings.general.scheduleReport = this.reportSettings;
      }

      this.$emit("update", workflowSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
